<template>
	<div class="main">
		<base-dialog
			:show="!!error"
			@close="resetError"
		>
			<p>{{ error }}</p>
		</base-dialog>
		<div>
			<coach-filter @change-filter="setFilters"></coach-filter>
		</div>
		<base-card>
			<div class="controls">
				<base-button mode="outline" @click="loadCoaches(true)"
					>Refresh</base-button
				>
				<base-button link to="/register" v-if="!isCoach && !isLoading && isLoggedIn"
					>Register as Coach</base-button
				>
				<base-button link to='/auth?redirect=/register' v-if="!isLoggedIn">Login to Register as Coach</base-button>
			</div>
			<div v-if="isLoading">
				<base-spinner></base-spinner>
			</div>
			<h3 v-else-if="!hasCoaches">
				No coaches added yet.
				<router-link to="/register">Register</router-link> Now!
			</h3>
			<ul v-else>
				<coach-item
					v-for="coach in filteredCoaches"
					:key="coach.id"
					:id="coach.id"
					:first-name="coach.firstName"
					:last-name="coach.lastName"
					:rate="coach.hourlyRate"
					:areas="coach.areas"
				></coach-item>
			</ul>
		</base-card>
	</div>
</template>

<script>
	import CoachItem from "../../components/coaches/CoachItem.vue";
	import CoachFilter from "../../components/coaches/CoachFilter.vue";

	export default {
		data() {
			return {
				activeFilters: {
					frontend: true,
					backend: true,
					career: true,
				},
				isLoading: true,
				error: null,
			};
		},
		components: {
			CoachItem,
			CoachFilter,
		},
		computed: {
			isLoggedIn() {
				return this.$store.getters.isAuthenticated;
			},
			isCoach() {
				return this.$store.getters["coaches/isCoach"];
			},
			hasCoaches() {
				return this.$store.getters["coaches/hasCoaches"];
			},
			filteredCoaches() {
				const coaches = this.$store.getters["coaches/allCoaches"];
				return coaches.filter((coach) => {
					if (
						this.activeFilters.frontend &&
						coach.areas.includes("frontend")
					) {
						return true;
					}
					if (
						this.activeFilters.backend &&
						coach.areas.includes("backend")
					) {
						return true;
					}
					if (
						this.activeFilters.career &&
						coach.areas.includes("career")
					) {
						return true;
					}

					return false;
				});
			},
		},
		methods: {
			setFilters(updatedFilters) {
				this.activeFilters = updatedFilters;
			},
			async loadCoaches(refresh = false) {
				this.isLoading = true;
				this.error = null;

				try {
					await this.$store.dispatch("coaches/loadCoaches", {forceRefresh: refresh});
					this.isLoading = false;
				} catch (e) {
					this.error = e.error.message || "Something went wrong!";
				}
			},
			resetError() {
				this.error = null;
			},
		},
		created() {
			this.loadCoaches();
		},
	};
</script>

<style scoped>
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.controls {
		display: flex;
		justify-content: space-between;
	}
</style>
