import { createRouter, createWebHashHistory } from "vue-router";

import CoachesList from "../views/coaches/CoachesList.vue";
import NotFound from "../views/NotFound.vue";

import store from "../store/index.js";

// Lazy instantiation for speed loading
const CoachDetails = () => import("../views/coaches/CoachDetails.vue");
const CoachRegistration = () =>
	import("../views/coaches/CoachRegistration.vue");
const ContactCoach = () => import("../views/requests/ContactCoach.vue");
const RequestsRecieved = () => import("../views/requests/RequestsReceived.vue");
const UserAuth = () => import("../views/auth/UserAuth.vue");

const routes = [
	{ path: "/", redirect: "/coaches" },
	{ path: "/coaches", name: "Coaches", component: CoachesList },
	{
		path: "/coaches/:id",
		name: "Coach",
		props: true,
		component: CoachDetails,
		children: [
			{ path: "contact", name: "Contact", component: ContactCoach },
		],
	},
	{
		path: "/register",
		name: "Register",
		component: CoachRegistration,
		meta: { requiresAuth: true },
	},
	{
		path: "/requests",
		name: "Requests",
		component: RequestsRecieved,
		meta: { requiresAuth: true },
	},
	{
		path: "/auth",
		name: "Auth",
		component: UserAuth,
		meta: { requiresUnauth: true },
	},
	{ path: "/:notFound(.*)", name: "Not Found", component: NotFound },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach((to, _, next) => {
	console.log(to);
	if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
		next({ path: "/auth", query: { redirect: to.fullPath } });
	} else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
		next("/coaches");
	} else {
		next();
	}
});

export default router;
