export default {
	namespaced: true,
	state() {
		return {
			requests: [],
		};
	},
	mutations: {
		addRequest(state, payload) {
			state.requests.push(payload);
		},
		saveRequests(state, payload) {
			state.requests = payload;
		},
		deleteRequest(state, payload) {
			state.requests = state.requests.filter(
				(req) => req.id !== payload.requestId
			);
		},
	},
	actions: {
		async contactCoach(context, payload) {
			const newRequest = {
				userEmail: payload.email,
				message: payload.message,
			};

			const response = await fetch(
				`https://vue-http-demo-a5445-default-rtdb.firebaseio.com/requests/${payload.coachId}.json`,
				{
					method: "POST",
					body: JSON.stringify(newRequest),
				}
			);

			const responseData = await response.json();

			if (!response.ok) {
				const error = new Error(
					response.message ??
						"An error orrured while sending your request."
				);
				throw error;
			}

			newRequest.id = responseData.name;
			newRequest.coachId = payload.coachId;
			context.commit("addRequest", newRequest);
		},
		async loadRequests(context) {
			const coachId = context.rootGetters.userId;
			const token = context.rootGetters.token;
			const response = await fetch(
				`https://vue-http-demo-a5445-default-rtdb.firebaseio.com/requests/${coachId}.json?auth=${token}`
			);

			if (!response.ok) {
				const error = new Error(
					response.message ??
						"An error occured while fetching requests."
				);
				throw error;
			}

			const responseData = await response.json();
			const requests = [];
			for (const key in responseData) {
				const newRequest = {
					id: key,
					coachId: coachId,
					userEmail: responseData[key].userEmail,
					message: responseData[key].message,
				};
				requests.push(newRequest);
			}
			context.commit("saveRequests", requests);
		},
		async delete(context, payload) {
			const response = await fetch(
				`https://vue-http-demo-a5445-default-rtdb.firebaseio.com/requests/${context.rootGetters.userId}/${payload.requestId}.json`,
				{ method: "DELETE" }
			);

			if (!response.ok) {
				const error = new Error(
					response.message ?? "Could not delete response."
				);
				throw error;
			}

			context.commit("deleteRequest", payload);
		},
	},
	getters: {
		allRequests(state) {
			return state.requests;
		},
		myRequests(_, getters, _2, rootGetters) {
			return getters.allRequests.filter(
				(req) => req.coachId === rootGetters.userId
			);
		},
		hasRequests(_, getters) {
			return getters.myRequests && getters.myRequests.length > 0;
		},
	},
};
