export default {
	namespaced: true,
	state() {
		return {
			coaches: [],
			lastFetch: null,
		};
	},
	mutations: {
		registerCoach(state, payload) {
			state.coaches.push(payload);
		},
		setCoaches(state, payload) {
			state.coaches = payload;
		},
		setFetchTimestamp(state) {
			state.lastFetch = new Date().getTime();
		},
	},
	actions: {
		async registerCoach(context, data) {
			const userId = context.rootGetters.userId;
			const coachData = {
				firstName: data.first,
				lastName: data.last,
				areas: data.areas,
				description: data.desc,
				hourlyRate: data.rate,
			};

			const token = context.rootGetters.token;
			const response = await fetch(
				`https://vue-http-demo-a5445-default-rtdb.firebaseio.com/coaches/${userId}.json?auth=${token}`,
				{
					method: "PUT",
					body: JSON.stringify(coachData),
				}
			);

			if (!response.ok) {
				// error
				return;
			}

			context.commit("registerCoach", {
				...coachData,
				id: userId,
			});
		},
		async loadCoaches(context, payload) {
			if (!payload.forceRefresh && !context.getters.shouldUpdate) {
				return;
			}

			const response = await fetch(
				"https://vue-http-demo-a5445-default-rtdb.firebaseio.com/coaches.json"
			);

			if (!response.ok) {
				const error = new Error(responseData.message ?? 'Failed to fetch coaches!');
				throw error;
			}

			const responseData = await response.json();
			const coaches = [];
			for (const key in responseData) {
				const newCoach = {
					id: key,
					firstName: responseData[key].firstName,
					lastName: responseData[key].lastName,
					description: responseData[key].description,
					hourlyRate: responseData[key].hourlyRate,
					areas: responseData[key].areas,
				};
				coaches.push(newCoach);
			}
			context.commit("setCoaches", coaches);
			context.commit("setFetchTimestamp");
		},
	},
	getters: {
		hasCoaches(state) {
			return state.coaches && state.coaches.length > 0;
		},
		allCoaches(state) {
			return state.coaches;
		},
		isCoach(_, getters, _2, rootGetters) {
			const coaches = getters.allCoaches;
			const userId = rootGetters.userId;
			return coaches.some((coach) => coach.id === userId);
		},
		shouldUpdate(state) {
			const lastFetch = state.lastFetch;
			if (!lastFetch) {
				return true
			}

			const currentTimestamp = new Date().getTime();
			return (currentTimestamp - lastFetch) / 1000 > 60;
		},
	},
};
