import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";

import BaseButton from "./components/ui/BaseButton.vue";
import BaseBadge from "./components/ui/BaseBadge.vue";
import BaseCard from "./components/ui/BaseCard.vue";
// import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";

const app = createApp(App);

const BaseDialog = defineAsyncComponent(() =>
	import("./components/ui/BaseDialog.vue")
);

app.component("base-button", BaseButton);
app.component("base-badge", BaseBadge);
app.component("base-card", BaseCard);
app.component("base-dialog", BaseDialog);
app.component("base-spinner", BaseSpinner);

app.use(store);
app.use(router);

app.mount("#app");
