const API_KEY = "AIzaSyBiJVj9QyXLoBGdoZ-uYFj44hNUrNhpFaw";
const signUpUrl = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${API_KEY}`;
const signInUrl = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${API_KEY}`;
let timer;

export default {
	store() {
		return {
			userId: null,
			token: null,
			didAutoLogout: false,
		};
	},
	mutations: {
		setUser(state, payload) {
			state.token = payload.token;
			state.userId = payload.userId;
            state.didAutoLogout = false;
		},
		setAutoLogout(state) {
			state.didAutoLogout = true;
		},
	},
	actions: {
		async auth(context, payload) {
			const mode = payload.mode;
			const url = mode === "signup" ? signUpUrl : signInUrl;
			const response = await fetch(url, {
				method: "POST",
				"content-type": "application/json",
				body: JSON.stringify({
					email: payload.email,
					password: payload.password,
					returnSecureToken: true,
				}),
			});

			const responseData = await response.json();
			if (!response.ok) {
				console.log(responseData);
				const errorMsg = responseData.error.message;
				let message = null;

				if (errorMsg === "EMAIL_EXISTS") {
					message =
						"The email address is already in use by another account. Try logging in instead.";
				}

				if (errorMsg === "OPERATION_NOT_ALLOWED") {
					message = "Password sign-in is disabled for this project.";
				}

				if (errorMsg === "TOO_MANY_ATTEMPTS_TRY_LATER") {
					message =
						"We have blocked all requests from this device due to unusual activity. Try again later.";
				}

				if (errorMsg === "EMAIL_NOT_FOUND") {
					message =
						"There is no user record corresponding to this email address. The user may have been deleted. Try signing up instead.";
				}

				if (errorMsg === "INVALID_PASSWORD") {
					message = "The password is invalid.";
				}

				if (errorMsg === "USER_DISABLED") {
					message =
						"The user account has been disabled. Please contact the administrator.";
				}

				const error = new Error(message ?? "Failed to authenticate.");
				throw error;
			}

			const expiresIn = +responseData.expiresIn * 1000;
			const expirationDate = new Date().getTime() + expiresIn;

			localStorage.setItem("token", responseData.idToken);
			localStorage.setItem("userId", responseData.localId);
			localStorage.setItem("tokenExpiration", expirationDate);

			timer = setTimeout(function() {
				context.dispatch("autoLogout");
			}, expiresIn);

			context.commit("setUser", {
				token: responseData.idToken,
				userId: responseData.localId,
			});
		},
		tryLogin(context) {
			const token = localStorage.getItem("token");
			const userId = localStorage.getItem("userId");
			const tokenExpiration = localStorage.getItem("tokenExpiration");

			const expiresIn = +tokenExpiration - new Date().getTime();

			if (expiresIn < 10000) {
				return;
			}

			timer = setTimeout(function() {
				context.dispatch("autoLogout");
			}, expiresIn);

			if (token && userId) {
				context.commit("setUser", {
					token: token,
					userId: userId,
				});
			}
		},
		async login(context, payload) {
			return context.dispatch("auth", {
				...payload,
				mode: "login",
			});
		},
		async signup(context, payload) {
			return context.dispatch("auth", {
				...payload,
				mode: "signup",
			});
		},
		logout(context) {
			localStorage.removeItem("token");
			localStorage.removeItem("userId");
			localStorage.removeItem("tokenExpiration");

			clearTimeout(timer);

			context.commit("setUser", {
				token: null,
				userId: null,
			});
		},
		autoLogout(context) {
			context.dispatch("logout");
			context.commit("setAutoLogout");
		},
	},
	getters: {
		userId(state) {
			return state.userId;
		},
		token(state) {
			return state.token;
		},
		isAuthenticated(state) {
			return !!state.token;
		},
        didAutoLogout(state) {
            return state.didAutoLogout;
        },
	},
};
