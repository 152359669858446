import { createStore } from "vuex";

import coachesModule from "./modules/coaches.js";
import requestsModule from "./modules/requests.js";
import authModule from "./modules/auth.js";

export default createStore({
	modules: {
		coaches: coachesModule,
		requests: requestsModule,
		auth: authModule,
	},
});
