<template>
  <div class="mainbox">
    <div class="err">4</div>
    <i class="far fa-question-circle fa-spin"></i>
    <div class="err2">4</div>
    <div class="msg">
      Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
      existed in the first place?
      <p>Let's go <router-link to="/">home</router-link> and try from there.</p>
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    let fa = document.createElement('script');
    fa.setAttribute('src', 'https://kit.fontawesome.com/4b9ba14b0f.js');
    fa.setAttribute('crossorigin', 'anonymous');
    document.head.appendChild(fa);
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap');

body {
  background-color: #95c2de !important;
  margin: 0 !important;
  padding: 0 !important;
}

.mainbox {
  background-color: #95c2de;
  margin: auto;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.err, .err2 {
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 11rem;
  display: inline-block;
}

.far {
  font-size: 8.5rem;
  color: #ffffff;
  display: inline-block;
  margin: 5rem 3rem;
}

.msg {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.6rem;
  display: block;
  width: 60%;
  margin: auto;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 760px) {
    .err, .err2 {
        font-size: 5rem;
    }

    .far {
        font-size: 4rem;
        margin: 3rem 1rem;
    }

    .msg {
        width: 80%;
    }
}
</style>
